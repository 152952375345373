const courseA = [
    {
        src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/home/m1.png',
        text:'课程资源丰富'
    },
    {
        src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/home/m2.png',
        text:'课程形式多样'
    },
    {
        src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/home/m3.png',
        text:'内容、学时合法合规'
    },
    {
        src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/home/m4.png',
        text:'权威专业主讲'
    },
    {
        src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/home/m5.png',
        text:'创新学习理念'
    },
    {
        src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/home/m6.png',
        text:'持续优化更新'
    }
]
  export default {
    courseA
  }