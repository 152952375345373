import { render, staticRenderFns } from "./homegw.vue?vue&type=template&id=438d1323&scoped=true"
import script from "./homegw.vue?vue&type=script&lang=js"
export * from "./homegw.vue?vue&type=script&lang=js"
import style0 from "../asset/css/home.scss?vue&type=style&index=0&id=438d1323&prod&lang=scss&scoped=true&external"
import style1 from "../../gpt/assets/css/index.scss?vue&type=style&index=1&id=438d1323&prod&lang=scss&scoped=true&external"
import style2 from "./homegw.vue?vue&type=style&index=2&id=438d1323&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "438d1323",
  null
  
)

export default component.exports